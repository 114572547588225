<template>
	<div class="page">
		<div class="viewT">
			<div class="viewTBase">
				<div class="viewTitle">基础配置</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>获取方式:</div>
						<el-checkbox-group v-model="checkList">
							<el-checkbox label="0">充值</el-checkbox>
							<el-checkbox label="1">余额消费</el-checkbox>
							<el-checkbox label="2">非余额消费</el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>用户类型:</div>
						<el-radio-group v-model="exchangeConfig">
							<el-radio :label="2">全部用户</el-radio>
							<el-radio :label="0">普通会员</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div style="display: flex;align-items: center;">
					<div class="viewTitle" style="margin-bottom: 0;">兑换商品</div>
					<el-button style="width: 110px;margin-left: 20px;" @click="handleAddGoodsClick">添加商品
					</el-button>
				</div>
			</div>
			<!-- 表格 -->
			<div class="tableView">
				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="barCode" label="条形码">
					</el-table-column>
					<el-table-column prop="goodsName" label="名称">
					</el-table-column>
					<el-table-column prop="model" label="规格">
					</el-table-column>
					<el-table-column prop="salePrice" label="售价">
					</el-table-column>
					<el-table-column prop="currentStock" label="当前库存">
					</el-table-column>
					<el-table-column prop="needIntegral" label="兑换积分" width="160">
						<template slot-scope="scope">
							<el-input-number v-model="scope.row.needIntegral" :step="1" :min="1" size="small"></el-input-number>
						</template>
					</el-table-column>
					<el-table-column prop="limitCount" label="限购数量" width="160">
						<template slot-scope="scope">
							<el-input-number v-model="scope.row.limitCount" :step="1" :min="1" size="small"></el-input-number>
						</template>
					</el-table-column>
					<el-table-column label="状态">
						<template slot-scope="scope">
							<span v-if="scope.row.status.id == '0'">未启动</span>
							<span v-if="scope.row.status.id == '1'">暂停中</span>
							<span v-if="scope.row.status.id == '2'">进行中</span>
							<span v-if="scope.row.status.id == '9'">已兑完</span>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope"> 
							<el-button @click="handleStatusClick(scope.row,1)" type="text" size="small"   v-if="(!scope.row.newGoods && scope.row.status.id == '2')&&$buttonAuthority('dianpu-jifen-state')">暂停</el-button>
							<el-button @click="handleStatusClick(scope.row,2)" type="text" size="small"  v-if="!scope.row.newGoods && (scope.row.status.id == '1' || scope.row.status.id == '9')&&$buttonAuthority('dianpu-jifen-state')">启动</el-button>
							<el-button @click="handleStatusClick(scope.row,-1)" type="text" size="small"  v-if="(scope.row.status.id == '1' || scope.row.status.id == '9')&&$buttonAuthority('dianpu-jifen-del')">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			
		</div>
		<div class="viewB" v-if="$buttonAuthority('dianpu-jifen-submit')">
			<el-button type="primary" style="width: 110px;margin-right: 20px;" @click="handleSubmitClick">确定
			</el-button>
		</div>
		<div v-if="dialogProduct_state">
			<dialogProduct @submit="submit_dialogProduct" :tree="tree" :ignoreGoods="ignoreGoods"></dialogProduct>
		</div>
	</div>
</template>

<script>
	import dialogProduct from "../../../components/dialogProduct.vue"
	export default {
		components:{
			dialogProduct
		},
		data() {
			return {
				checkList: ['0','1','2'],  //选择获取方式
				tree:[{code:'goodstype'}],  //商品分类
				dialogProduct_state:false,  //添加商品弹框
				ignoreGoods:'',  //忽略商品id
				tableData:[],  //商品列表
				exchangeConfig:2,  //用户类型
			}
		},
		mounted() {
			this.getInteralData()
		},
		methods: {
			//更改状态
			handleStatusClick(row,type){
				let params = {
					id:row.goodsId,
					status:type
				}
				this.$http.post("/product/Integral/update/status",params).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '已变更'
						});
						this.getInteralData()
					}
				})
			},
			//确定
			handleSubmitClick() {
				if(this.checkList.length == 0){
					this.$message({
						type: 'error',
						message: '请选择获取方式'
					});
					return
				}
				if(this.tableData.length == 0){
					this.$message({
						type: 'error',
						message: '请选择兑换商品'
					});
					return
				}
				let params = {
					exchangeConfig:this.exchangeConfig+'',
					getWay:{
						enableRecharge:false,
						enableMemberConsumeBalance:false,
						enableNotMemberConsumeBalance:false
					},
					exchangeGoodsInfos:this.tableData
				}
				//获取方式
				this.checkList.map((item)=>{
					if(item == '0'){
						params.getWay.enableRecharge = true;
					}
					if(item == '1'){
						params.getWay.enableMemberConsumeBalance = true;
					}
					if(item == '2'){
						params.getWay.enableNotMemberConsumeBalance = true;
					}
				})
				this.$http.post("/product/Integral/update",params).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '已提交'
						});
						this.getInteralData()
					}
				})
			},
			//获取积分信息
			getInteralData(){
				this.$http.get("/product/Integral/info").then(res => {
					if (res.code === 0) {
						this.tableData = res.data.exchangeGoodsInfos;
						this.exchangeConfig = Number(res.data.exchangeConfig.id);
						let arr = [];
						if(res.data.getWay.enableRecharge == true){
							arr.push('0')
						}
						if(res.data.getWay.enableMemberConsumeBalance == true){
							arr.push('1')
						}
						if(res.data.getWay.enableNotMemberConsumeBalance == true){
							arr.push('2')
						}
						this.checkList = arr;
					}
				})
			},
			//点击添加商品
			handleAddGoodsClick(){
				if(this.tableData.length != 0){
					let str = ''
					this.tableData.map((item)=>{
						str = str+item.goodsId+','
					})
					this.ignoreGoods = str;
				}
				this.dialogProduct_state = true;
			},
			// 返回产品
			submit_dialogProduct(obj){
				let arr = obj.data;
				if(arr.length != 0){
					let newArr = [];
					arr.map((item)=>{
						newArr.push({
							goodsId:item.newData.productId,
							needIntegral:1,
							limitCount:1,
							status:{
								id:'2',
								name:'进行中'
							},
							barCode:item.newData.barCode,
							goodsName:item.newData.productName,
							model:item.newData.modelValue,
							salePrice:item.newData.originalPrice,
							currentStock:item.newData.currentStock,
							newGoods:'新增'
						})
					})
					this.tableData = this.tableData.concat(newArr);
					this.dialogProduct_state = false;
				}else{
					this.dialogProduct_state = false;
				}
				
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;
		display: flex;
		flex-direction: column;
		background: #fff;
		border-radius: 20px;

		.viewT {
			height: calc(100% - 68px);
			flex: 1;
			display: flex;
			flex-direction: column;
			.viewTitle {
				width: 64px;
				padding-left: 9px;
				font-size: 16px;
				font-weight: bold;
				background: url(../../../assets/img/cangku/detailName.png) no-repeat;
				background-position: left top;
				background-size: 16px 16px;
				margin-bottom: 20px;
			}
			.tableView{
				flex: 1;
				overflow-y: scroll;
			}
			.tableView::-webkit-scrollbar {
				display: none;
			}
			.viewTBase {
				padding: 32px 32px 20px 32px;

				.comView {
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					font-size: 14px;

					.comItem {
						display: flex;
						align-items: center;
						height: 40px;

						.comItemName {
							width: 70px;
							text-align: right;
							margin-right: 8px;

							span {
								color: #FF3939;
							}
						}
					}

					.comTxt {
						color: #666;
						margin-left: 12px;

						span {
							color: #F77E04;
							cursor: pointer;
						}
					}
				}
			}
		}

		.viewB {
			height: 68px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			box-sizing: border-box;
			border-top: solid 1px #F2F2F2;
		}
	}
</style>
